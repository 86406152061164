import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope } from "react-icons/bs";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Visit Ryold - Пивна спортсмена
			</title>
			<meta name={"description"} content={"Де п'ють чемпіони і грають новачки"} />
			<meta property={"og:title"} content={"Visit Ryold - Пивна спортсмена"} />
			<meta property={"og:description"} content={"Де п'ють чемпіони і грають новачки"} />
			<meta property={"og:image"} content={"https://visitryold.com/images/bg.jpg"} />
			<link rel={"shortcut icon"} href={"https://visitryold.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://visitryold.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://visitryold.com/images/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://visitryold.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0 0px 0"
			sm-padding="40px 0"
			background="--color-darkL2"
		>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Контакти
			</Text>
			<Text as="p" font="--lead" margin="40px 50px 20px">
				Ознайомтеся з нашим повним меню в Visit Ryold або зв'яжіться з нами для отримання детальної інформації. Пам'ятайте, чемпіони не зупиняються на досягнутому - вони досліджують!
			</Text>
		</Section>
		<Section padding="10px 0 80px 0" background="--color-darkL2" quarkly-title="Contacts-7">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="0px 0px 0 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Адреса
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						вул. Келецька, 81, м. Вінниця, 21000
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Email
					</Text>
					<Link
						href="mailto:contact@visitryold.com"
						color="#dae0e5"
						text-decoration-line="initial"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 0 0px"
						text-align="center"
					>
						contact@visitryold.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 0px 20px 0px"
						color="#dae0e5"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--light"
						md-margin="0px 0px 20px 0px"
					>
						Телефон
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="#dae0e5"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						+38 (073) 883 4715
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});